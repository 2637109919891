* {-webkit-font-smoothing:subpixel-antialiased; -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;font-kerning:auto;}.html{-webkit-text-size-adjust:none;-webkit-font-smoothing:subpixel-antialiased;-webkit-font-smoothing:antialiased}i{font-style:normal;}a,body,div,footer,h1,h2,h3,h4,h5,h6,header,html,img,li,nav,p,span,textarea,ul{margin:0;padding:0;border:0;outline:0;}h1,h2,h3,h4,h5,h6{font-weight:normal;line-height:1;}li,ul{list-style:none;}a{color:inherit;text-decoration:none;}a,img,picture,svg,video{display:block;}svg{max-width:100%;height:auto;}html{-ms-text-size-adjust:100%;-webkit-overflow-scrolling:touch;-webkit-tap-highlight-color:transparent;}*, :after, :before {-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
.flickity-enabled{position:relative}.flickity-enabled:focus{outline:0}.flickity-viewport{position:relative;height:100%;}.flickity-slider{position:absolute;width:100%;will-change: transform;}.flickity-enabled.is-draggable{-webkit-tap-highlight-color:transparent;tap-highlight-color:transparent;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.flickity-enabled.is-draggable .flickity-viewport{cursor: move;cursor: -webkit-grab;cursor: grab;} .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down{cursor: -webkit-grabbing;cursor: grabbing;}.flickity-prev-next-button{position:absolute;top:50%;width:44px;height:44px;border:none;border-radius:50%;background:#fff;background:hsla(0,0%,100%,.75);-webkit-transform:translateY(-50%);transform:translateY(-50%)}.flickity-prev-next-button:hover{background:#fff}.flickity-prev-next-button:focus{outline:0;box-shadow:0 0 0 5px #09f}.flickity-prev-next-button:active{opacity:.6}.flickity-prev-next-button.previous{left:10px}.flickity-prev-next-button.next{right:10px}.flickity-rtl .flickity-prev-next-button.previous{left:auto;right:10px}.flickity-rtl .flickity-prev-next-button.next{right:auto;left:10px}.flickity-prev-next-button:disabled{opacity:.3;}.flickity-prev-next-res_button svg{position:absolute;left:20%;top:20%;width:60%;height:60%}.flickity-prev-next-button .arrow{fill:#333}.flickity-page-dots{position:absolute;width:100%;bottom:-25px;padding:0;margin:0;list-style:none;text-align:center;line-height:1}.flickity-rtl .flickity-page-dots{direction:rtl}.flickity-page-dots .dot{display:inline-block;width:10px;height:10px;margin: 0 4px;background:#333;border-radius:50%;opacity:.25;}flickity-page-dots .dot.is-selected{opacity:1}
html.has-scroll-smooth{overflow:hidden}html.has-scroll-dragging{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.has-scroll-smooth body{overflow:hidden}.has-scroll-smooth [data-scroll-container]{min-height:100vh}[data-scroll-direction=horizontal] [data-scroll-container]{display:inline-block;height:100vh;}[data-scroll-direction=horizontal] [data-scroll-section]{display:inline-block;height:100%;vertical-align:top;}[data-scroll-direction=horizontal] .c-scrollbar{visibility:hidden;}.c-scrollbar{height:100%;opacity:0;position:absolute;right:0;top:0;transform-origin:center right;transition:transform .3s,opacity .3s;width:11px}.c-scrollbar:hover{transform:scaleX(1.45)}.c-scrollbar:hover,.has-scroll-dragging .c-scrollbar,.has-scroll-scrolling .c-scrollbar{opacity:1}[data-scroll-direction=horizontal] .c-scrollbar{bottom:0;height:10px;top:auto;transform:scaleY(1);width:100%}[data-scroll-direction=horizontal] .c-scrollbar:hover{transform:scaleY(1.3)}.c-scrollbar_thumb{background-color:#000;border-radius:10px;cursor:-webkit-grab;cursor:grab;margin:2px;opacity:.5;position:absolute;right:0;top:0;width:7px}.has-scroll-dragging .c-scrollbar_thumb{cursor:-webkit-grabbing;cursor:grabbing}[data-scroll-direction=horizontal] .c-scrollbar_thumb{bottom:0;right:auto}

/* Loader */
#loader svg {
	-webkit-animation: loader 2s linear infinite;
	animation: loader 2s linear infinite;
}

@-webkit-keyframes loader {
	0% {transform: scale(1)}
	50% {transform: scale(1.1)}
	100% {transform: scale(1)}
}

@keyframes loader {
	0% {transform: scale(1)}
	50% {transform: scale(1.1)}
	100% {transform: scale(1)}
}

._temp {
	z-index: 1999;
}

.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 20px;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	z-index: 1999;
	pointer-events: none;
}

.spinner:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border: 2px solid rgba(255, 255, 255, 0.3);
	border-bottom-color: #fff;
}

.spinner:not(.pause):before {
	-webkit-animation: rotateSpinner 300ms linear infinite;
	animation: rotateSpinner 300ms linear infinite;
}

@-webkit-keyframes rotateSpinner {
	to {
	-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
	}
}

@keyframes rotateSpinner {
	to {
	-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
	}
}
/* Fonts */
@font-face {
	font-family: 'MinionPro';
	src: url('../static/fonts/MinionPro-Regular.woff') format('woff'),
		url('../static/fonts/MinionPro-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'louize';
	src: url('../static/fonts/louize.woff') format('woff'),
		url('../static/fonts/louize.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'louize-mediumitalic';
	src: url('../static/fonts/louize-mediumitalic.woff') format('woff'),
		url('../static/fonts/louize-mediumitalic.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'concourse';
	src: url('../static/fonts/concourse_t4_regular.woff') format('woff'),
		url('../static/fonts/concourse_t4_regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'northwell';
	src: url('../static/fonts/northwell-alt.woff') format('woff'),
		url('../static/fonts/northwell-alt.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

body,
input,
textarea {
	font-family: 'concourse';
	font-size: 20px;
}

/* Transitions */
body:not(.progress) .circle > i,
body:not(.progress) .circle_text,
body:not(.progress) .circle_content,
body:not(.progress) .circle p {
	transition: all 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
	-webkit-transition: all 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

body:not(.progress) .button.outline,
body:not(.progress) .rounded_button,
body:not(.progress) .gallery_nav h6 {
	transition: color 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
	-webkit-transition: color 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

body:not(.progress) .button.outline,
body:not(.progress) .rounded_button {
	transition: background 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
	-webkit-transition: background 400ms cubic-bezier(0.22, 0.61, 0.36, 1);
}

*[data-scroll],
div[data-scroll-section],
._eleY,
._eleX,
.SplitClass,
.circle > i,
.circle_text,
.circle_content,
.circle p,
.gallery_col i span {
	-webkit-text-rendering: optimizeSpeed;
	text-rendering: optimizeSpeed;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	will-change: transform;
}

._eleWrap,
._splitWords,
._splitLines {
	visibility: hidden;
}

/* Global */
main {
	position: relative;
	overflow: hidden;
}

.flex {
	display: flex;
}

.col {
	flex-direction: column;
}

.space-between {
	justify-content: space-between;
}

.align-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.small_screen {
	display: none;
}

.ratio_section {
	height: 56.25vw;
	color: #fff;
	position: relative;
}


/* Typography  */
h1 {
	font-family: 'louize';
}

.louize,
h3,
h4,
h5,
i {
	font-family: 'louize-mediumitalic';
}

.alt_font,
h2,
h6 {
	font-family: 'MinionPro';
}

.hand_font {
	font-family: 'northwell';
}

h1 {
	font-size: 10.4166vw;
}

h2 {
	font-size: 8.854vw;
}

h3 {
	font-size: 5.2vw;
}

h4 {
	font-size: 4.6vw;
}

h5 {
	font-size: 3.1vw
}

h6 {
	font-size: 2.6vw;
}

p {
	font-size: 1.25vw;
	line-height: 140%;
}

p,
.min {
	color: rgba(0,0,0,0.7);
}

.section_dark p,
.details_box p,
.section_dark .min {
	color: rgba(255,255,255,0.7);
}

.min {
	font-size: 1vw;
	line-height: 120%;
}

.uppercase {
	text-transform: uppercase;
}

.zero-font > span {
	font-size: 0;
	position: absolute;
}

.full_bg {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.cover {
	background-size: cover;
	background-position: center;
}

/* Header */
header {
	color: #fff;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1999;
}

.header_set {
	padding: 40px;
}

.button {
	border-radius: 100px;
	padding: 1.04166vw 1.5625vw;
	font-size: 1.041666vw;
}

.button:not(.outline) {
	background: #000;
	color: #fff;
}

.button.light {
	background: #fff;
	color: #000;
}

.button.outline {
	border: 1px solid rgba(255,255,255,0.6);
}

.button.outline:hover,
.header_side .rounded_button:hover {
	color: #000;
	background: #fff;
}

.button svg {
	margin-right: 0.625vw;
	width: 0.833vw;
}

.rounded_button {
	width: 3.385vw;
	height: 3.385vw;
	border: 1px solid rgba(255,255,255,0.6);
	border-radius: 50%;
}

.rounded_button svg {
	width: 1.35416vw;
}

.header_side a:last-child {
	margin-left: 1.041666vw;
}

.logo {
	width: 3.3854vw;
	min-width: 50px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

/* Global */
.section_set {
	padding-top: 7.8125vw;
}

.section_dark {
	padding-bottom: 7.8125vw;
}

.section_set:not(.fluid) {
	padding-right: 40px;
	padding-left: 40px;
}

.margin-bottom {
	margin-bottom: 5.2083vw;
}

/* Main */
.main_section {
	background: #000;
}

.heading .heading_row:first-child {
	left: -4vw;
	z-index: 199;
}

.heading .heading_row:last-child {
	align-self: flex-end;
	left: 4vw;
	margin-top: -1vw;
}

.heading_row:not(.align-center) {
	align-items: baseline;
}

.heading_row {
	position: relative;
}

.heading .heading_row h4,
.inner_heading .heading_row:not(:first-child) h4 {
	margin-right: 1vw;
}

.heading_row h4 {
	position: relative;
}

.video_wrap {
	position: absolute;
	z-index: 699;
	left: 0;
	right: 0;
	bottom: -3.645vw;
}

.video_block {
	width: 36.458vw;
	height: 20.5078125vw;
	background: #000;
	margin-right: 3vw;
	position: relative;
}

.play {
	width: 6.77vw;
	height: 6.77vw;
	position: relative;
	z-index: 199;
}

.play svg {
	width: 1.5625vw;
}

.play > i {
	border: 1px solid rgba(255, 255, 255, 0.8);
	border-radius: 50%;
}

.leaves_set i:first-child {
	z-index: 120;
}

.leaves_set i:nth-child(2) {
	z-index: 99;
}

/* Intro */
.heading_row p {
	max-width: 22vw;
	margin-left: 2vw;
}

.inner_heading .heading_row:last-child {
	margin-top: 1vw !important;
}

.outer_paragraph {
	margin-top: 3vw;
	text-align: center;
}

/* Locations */
.locations_section {
	margin-bottom: 11.5625vw;
	position: relative;
}

.locations_cover {
	background: #000;
	width: 85.41666vw;
	height: 54.5833vw;
	position: relative;
}

.locations_block {
	width: 73vw;
	height: 29.4791vw;
	position: absolute;
	z-index: 399;
	right: 0;
	bottom: -11.5625vw;
	background: #303A2B;
}

.location_side_img {
	background: #111;
	width: 46%;
	position: relative;
}

.lcd_col:first-child {
	font-family: MinionPro;
	font-size: 18.75vw;
	line-height: 1;
	margin-left: -4vw;
	position: relative;
	top: -1vw;
}

.lcd_col:last-child {
	position: relative;
	left: 2vw;
}

.location_side_wrap {
	overflow: hidden;
}

.location_side_content {
	color: #fff;
}

.marg_arrows {
	margin-top: 2vw;
}

.rounded_arrow {
	width: 3.95833vw;
	height: 3.95833vw;
	border: 1px solid rgba(0,0,0,0.7);
	border-radius: 50%;
	min-width: 35px;
	min-height: 35px;
	cursor: pointer;
}

.rounded_arrow:first-child {
	margin-right: 0.78125vw;
}

.lcd_col_arrows .rounded_arrow,
.section_dark .rounded_arrow,
.map_box .rounded_arrow,
.panel_arrows .rounded_arrow {
	border-color: rgba(255,255,255,0.7);
}

/* Text Sides */
.text_sides,
.para_sides {
	width: 71.35416vw;
	margin-left: auto;
	margin-right: auto;
	justify-content: space-between;
	align-items: flex-start;
}

.text_side:last-child {
	max-width: 30.2vw;
}

.text_side p:not(:first-child),
.para_sides p:not(:first-child) {
	margin-top: 2vw;
}

/* Paragraph Sides */
.para_side {
	width: 31.77vw;
}

.arc_slider {
	margin: 0 auto;
	margin-bottom: 3.125vw;
	width: 71.35416vw;
}

.arc_slide {
	margin: 0 20px;
	height: 29.6875vw;
}

.arc_slide img {
	height: 100%;
	width: auto;
}

/* Figuers */
.section_dark {
	background: #111111;
	color: #fff;
}

/* Map */
.map_section {
	background: #000;
}

.map_markers {
	z-index: 99;
}

.map_box {
	width: 32.5vw;
	background: #111;
	bottom: 40px;
	left: 40px;
	position: absolute;
	z-index: 199;
	padding: 2.6vw;
	align-items: flex-end;
}

.map_selector {
	width: 14vw;
}

/* Figures  */
.cicles_wrap {
	flex-wrap: wrap;
	max-width: 75vw;
	margin: 0 auto;
	visibility: hidden;
}

.circle {
	position: relative;
	width: 16.1458vw;
	height: 16.1458vw;
	border: 1px solid rgba(255,255,255,0.1);
	border-radius: 50%;
	margin: 1.0416vw;
}

.circle_svg {
	margin-bottom: 0.7vw;
	height: 4vw;
}

.circle_svg svg {
	width: auto;
	height: 100%;
}

.circle > i {
	background: white;
	border-radius: 50%;
	transform: scale(0);
}

.circle h6 span:last-child {
	font-size: 1.25vw;
}

.circle h6 sub {
	top: -0.6vw;
	position: relative;
}

.circle:hover {
	z-index: 99;
}

.circle_text {
	transform: translateY(1vw);
    flex-direction: column;
}

.circle_content {
	flex-direction: column;
	mix-blend-mode: exclusion;
}

.circle p {
	max-width: 90%;
	text-align: center;
	line-height: 1;
	opacity: 0;
}

/* Info */
.box_sides {
	height: 54.53125vw;
}
.box_side {
	position: relative;
}

.box_side.has_info {
	width: 46.875vw;
	background: #303A2B;
	color: #fff;
}

.box_side:not(.has_info) {
	width: 53.125vw;
	background: #000;
}

.info_panel {
	flex-direction: column;
}

.info_logo {
	position: absolute;
	bottom: 40px;
	left: 40px;
}

.info_logo svg {
	width: 4.58333vw;
}

.info_text h2 {
	margin-top: 4vw;
}

.info_text {
	margin-top: -3vw;
	text-align: center;
}

.panel_arrows {
	position: absolute;
	bottom: 40px;
	right: 40px;
}

/* Our Story */
.story_section {
	flex-direction: column;
}

.story_head p {
	margin: 2vw 0 3vw
}

.story_head {
	width: 35.67vw;
	text-align: center;
}

.story_media {
	width: 78.15vw;
	align-items: self-end;
	position: relative;
	padding-top: 13.958vw
}

.story_image {
	background: #000;
	position: relative;
}

.story_image:nth-child(1) {
	width: 42.6vw;
	height: 31.953125vw;
}

.story_image:nth-child(2) {
	width: 25.703vw;
	height: 19.277vw;
	margin-bottom: 3.385vw;
}

.story_image:nth-child(3) {
	width: 27.2515625vw;
	height: 34.88177vw;
	background: #222;
	position: absolute;
	z-index: 99;
	margin-bottom: 10.989vw;
	right: 12.9166vw;
}

/* Hero */
.hero_section {
	min-height: 500px;
	background: #000;
	color: #fff;
	position: relative;
	text-align: center;
	overflow: hidden;
}

.hero_content {
	position: relative;
	z-index: 99;
}

/* Gardens */
.sub_heading .heading_row h4 {
	margin: 0 1vw;
}

.sub_heading .heading_row:not(:first-child) {
	margin-top: 1vw;
}

.gardens_gallery {
	width: 100%;
}

.gardens_img {
	width: 24.84375vw;
	height: 24.84375vw;
	flex: 24.84375vw 0 0;
	background: #000;
	margin: 0 1.3vw;
	min-width: 190px;
	min-height: 190px;
	position: relative;
}

/* Floors */
.floors_wrap {
	background: #C9D5D7;
	position: relative;
	height: 100%;
}

.details_box {
	background: rgb(0 0 0 / 80%);
	flex-direction: column;
	height: 41.25vw;
	left: 8.333vw;
	padding: 4.1vw;
	position: absolute;
	width: 32.291vw;
	align-items: flex-start;
	justify-content: center;
}

.floor_letter {
	align-items: baseline;
	line-height: 1;
	height: 14.3vw;
	margin-bottom: 2vw;
	position: relative;
	color: #758A62;
}

.floor_letter span {
	top: -4vw;
	left: -1vw;
	position: relative;
}

.floor_letter span:first-child {
	font-size: 22.3958vw;
}

.floor_letter span:last-child {
	font-size: 7.8125vw;
	left: -2vw;
}

.details_box p {
	padding: 1.5625vw 0;
}

.box_close {
	position: absolute;
	right: 0;
	width: 3.125vw;
	height: 3.125vw;
	background: #758A62;
	border-radius: 50%;
	transform: translateX(40%);
	cursor: pointer;
}

.box_close svg {
	width: 0.72916vw;
}

/* Gallery */
.gallery_section {
	flex-direction: column;
	align-items: center;
}

.gallery_wrap {
	width: 100%;
}

.gallery_col {
	padding: 0 2vw;
}

.gallery_col img {
	visibility: hidden;
	height: 100%;
	width: auto;
}

.gallery_col i {
	height: 32.69vw;
	overflow: hidden;
	display: block;
	margin-bottom: 1.5vw;
	position: relative;
}

.gallery_col i span {
	position: absolute;
	top: 0;
	right: -3vw;
	bottom: 0;
	left: -3vw;
}

.gallery_nav h6 {
	padding: 1.3vw;
	cursor: pointer;
}

.gallery_nav h6:not(.active) {
	color: rgba(255,255,255,0.5);
}

/* Contact */
input,
textarea {
	width: 100%;
	appearance: none;
	-webkit-appearance: none;
	border: none;
	outline: none;
	border-bottom: 1px solid rgba(0,0,0,0.3);
	padding: 14px 0;
	background: none;
}

textarea {
	min-height: 44px;
	height: 60px;
	resize: vertical;
}

.form_set {
	flex-direction: column;
	align-items: center;
}

.form_row {
	width: 100%;
	max-width: 41.66vw;
	position: relative;
	z-index: 99;
}

.form_row:not(:first-child) {
	margin-top: 30px;
}

.form_row:last-child {
	text-align: center;
}

.form_row a {
	display: inline-block;
}

/* Footer */
footer {
  background: #000;
  color: rgb(255 255 255 / 0.5);
  margin-top: 7.8125vw;
  font-size: 14px;
  line-height: 18px;
  padding: 0 40px;
}

footer p {
  background: #000;
  color: #fff !important;
}

.footer_set {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 0 50px;
}

.links a {
  padding: 5px;
}

.links > span {
  margin: 0 10px
}

.footer_col {
	margin-bottom: 80px;
	max-width: 400px;
}

.footer_col:first-child {
	margin-right: 80px;
}

.footer_col p {
	font-size: 20px;
	margin-bottom: 15px;
}

.footer_input {
	position: relative;
	margin-bottom: 12px;
}

.footer_input input {
	border-color: rgba(255,255,255,0.4);
}

.footer_input .submit {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: -10px;
	padding: 15px;
}

.footer_input .submit svg {
	width: 19px;
}

.social_list a {
	padding: 10px 20px;
}

.social_list li:first-child a {
	padding-left: 0;
}

.info {
	color: rgba(255,255,255,0.4);
	max-width: 80%;
}

/* Responsiveness */
@media (max-width: 1280px){
	.header_set {
		justify-content: flex-end;
	}
	.logo {
		left: 40px;
		transform: none;
	}
}

@media (max-width: 960px){
	.header_set {
		height: 136px;
	}
	.header_side .button,
	.header_side .rounded_button {
		width: 65px;
		height: 65px;
		padding: 0;
	}
	.header_side .button span {
		display: none;
	}
	.header_side .button svg,
	.rounded_button svg {
		width: auto;
		max-width: 18px;
		height: 17px;
	}
	.header_side .button svg {
		margin-right: 0;
	}
	.header_side a:last-child {
		margin-left: 10px;
	}
	.footer_side {
		flex-direction: column-reverse;
	}
	.footer_col:first-child {
		margin-bottom: 40px;
	}
}

@media (max-width: 768px){
	h1 {
		font-size: 13vw;
	}
	h2 {
		font-size: 10vw;
	}
	h3 {
		font-size: 7vw;
	}
	h4 {
		font-size: 6vw;
	}
	h5 {
		font-size: 4.1vw;
	}
	h6 {
		font-size: 3.8vw;
	}
	p {
		font-size: 2.7vw;
	}
	.min {
		font-size: 2vw;
	}
	.section_set {
		padding-top: 100px;
	}
	footer {
		margin-top: 100px;
	}
	.margin-bottom {
		margin-bottom: 60px;
	}
	section .button {
		padding: 15px 24px;
		font-size: 15px;
	}
	section .button svg {
		width: 13px;
		margin-right: 8px;
	}
	.header_set {
		height: 116px;
	}
	.header_side .button,
	.rounded_button {
		height: 55px;
		width: 55px;
	}
	.main_section {
		height: auto;
		padding: 150px 40px;
		padding-bottom: 0;
		margin-bottom: 50px;
	}
	.heading_row:not(:first-child) {
		margin-top: 0 !important;
	}
	.video_wrap {
		bottom: -50px;
		position: relative;
	}
	.video_block_set {
		flex-direction: column-reverse;
		align-items: center;
	}
	.video_wrap,
	.video_block_set,
	.video_block {
		width: 100%
	}
	.video_block {
		margin-right: 0;
		height: 50.390625vw;
	}
	.video_text br {
		display: none;
	}
	.video_text {
		margin-bottom: 30px;
	}
	.play {
		width: 12vw;
		height: 12vw;
	}
	.play svg {
		width: 3vw;
	}
	.heading_row p {
		margin-left: 2vw;
		max-width: 44vw;
	}
	.sub_heading .heading_row h4 {
		margin: 0 2vw;
	}
	.heading .heading_row h4,
	.inner_heading .heading_row:not(:first-child) h4 {
		margin-right: 2vw;
	}
	.rounded_arrow:first-child {
		margin-right: 10px;
	}
	.rounded_arrow svg {
		width: 14px;
	}
	.map_box {
		width: 40vw;
	}
	.marg_arrows {
		margin-top: 3vw;
	}
	.locations_cover {
		height: 63.932vw;
		width: 100vw;
	}
	.locations_block {
		right: auto;
		left: 50%;
		bottom: -50px;
		transform: translateX(-50%);
		width: calc(100vw - 80px);
		height: 36.588vw;
	}
	.locations_section {
		margin-bottom: 50px
	}
	.lcd_col:first-child {
		font-size: 21vw;
		margin-left: -5vw;
		top: -2vw;
	}
	.cicles_wrap {
		max-width: 100%;
	}
	.circle {
		height: 25vw;
		width: 25vw;
		margin: 1.5vw;
	}
	.circle_svg {
		height: 6vw;
		margin-bottom: 1vw;
	}
	.circle p {
		max-width: 75%;
	}
	.circle h6 span:last-child {
		font-size: 2vw;
	}
	.circle h6 sub {
		top: -0.8vw;
	}
	.circle_text {
		transform: translateY(3.5vw);
	}
	.circle p {
		font-size: 3vw !important;
	}
	.box_sides {
		flex-direction: column-reverse;
		height: auto;
	}
	.box_side {
		width: 100% !important;
	}
	.box_side:not(.has_info) {
		height: 102.34375vw;
	}
	.info_panels,
	.info_panel {
		position: relative;
	}
	.info_text {
		padding: 15vw 20px;
	}
	.info_logo svg {
		width: 6vw
	}
	.lcd_col:last-child {
		left: 3vw;
	}
	.arc_slider,
	.text_sides,
	.para_sides {
		width: 100%
	}
	.para_sides {
		flex-direction: column;
		align-items: flex-start
	}
	.para_side:not(:first-child) {
		margin-top: 20px;
	}
	.para_side {
		width: 80%;
	}
	.text_side:last-child {
		max-width: 50vw;
	}
	.story_head {
		width: 70%;
	}
	.story_head p {
		margin: 3vw 0 4vw
	}
	.floor_letter {
		display: none;
	}
	.details_box {
		height: auto;
		left: 70px;
		padding: 5vw;
		width: 53vw;
	}
	.details_box p {
		padding: 3vw 0;
	}
	.floors_section {
		height: 80vw;
	}
	.box_close {
		height: 40px;
		width: 40px;
		transform: translateX(50%);
	}
	.arc_slide {
		height: 40vw;
		margin: 0 10px;
	}
	.form_row {
		max-width: 100%;
	}
}

@media (max-width: 640px){
	.footer_top {
		flex-direction: column;
		align-items: flex-start;
	}
	.footer_side {
		flex-direction: column-reverse;
	}
	.footer_side:first-child {
		margin-bottom: 40px;
	}
	.footer_col {
		max-width: 100%;
		margin: 0;
	}
	.footer_col:first-child {
	  margin-top: 40px
	}
}

@media (min-width: 501px){
	.circle:hover {
		z-index: 99;
	}
	.circle:hover i {
		transform: scale(1.3);
	}
	.circle:hover .circle_content {
		transform: scale(1.1);
	}
	.circle:hover .circle_text {
		transform: translateY(0);
	}
	.circle:hover p {
		opacity: 1;
	}
}

@media (max-width: 500px){
	h1 {
		font-size: 14vw;
	}
	h2 {
		font-size: 10.5vw;
	}
	h3 {
		font-size: 8vw;
	}
	h4 {
		font-size: 7vw;
	}
	h5 {
		font-size: 6vw;
	}
	h6 {
		font-size: 5vw;
	}
	p {
		font-size: 4vw !important;
	}
	.margin-bottom {
		margin-bottom: 40px;
	}
	.large_screen {
		display: none;
	}
	.small_screen {
		display: block;
	}
	footer,
	.main_section {
		padding-left: 20px;
		padding-right: 20px;
	}
	.section_set {
		padding-top: 60px;
	}
	.section_dark {
		padding-bottom: 60px;
	}
	.section_set:not(.fluid) {
		padding-right: 20px;
		padding-left: 20px;
	}
	.header_set {
		padding: 20px;
	}
	.logo {
		left: 20px;
	}
	.main_section {
		margin-bottom: 30px;
	}
	.video_wrap {
		bottom: -30px;
	}
	.marg_arrows {
		margin-top: 4vw;
	}
	.map_box {
		bottom: 10px;
		left: 20px;
		padding: 10px;
		width: auto
	}
	.map_selector {
		width: auto;
		max-width: 60vw;
	}

	.map_selector h6 {
		font-size: 15px
	}
	.map_arrows {
	    display: none;
	}
	.cicles_wrap {
		padding: 0 30px;
		display: block;
	}
	.circle {
		height: 35vw;
		width: 35vw;
		margin: 0;
		margin-top: 30px;
		margin-right: 20px;
	}
	.circle_svg {
		height: 8vw;
		margin-bottom: 1.4vw;
	}
	.circle h6 span:last-child {
		font-size: 3vw;
	}
	.circle h6 sub {
		top: -1.15vw;
	}
	.circle_text {
		transform: translateY(5vw);
	}
	.circle.is-selected {
		z-index: 99;
	}
	.circle.is-selected i {
		transform: scale(1.3);
	}
	.circle.is-selected .circle_content {
		transform: scale(1.1);
	}
	.circle.is-selected .circle_text {
		transform: translateY(0);
	}
	.circle.is-selected p {
		opacity: 1;
	}
	.info_text {
		padding: 18vw 20px;
	}
	.panel_arrows {
		bottom: 20px;
		right: 20px;
	}
	.info_logo {
		bottom: 20px;
		left: 20px;
	}
	.info_logo svg {
		width: 10vw
	}
	.locations_block {
		width: calc(100vw - 40px);
	}
	.lcd_col:first-child {
		font-size: 23vw;
		margin-left: -5vw;
		top: -4vw;
	}
	.lcd_col:last-child {
		left: 4vw;
	}
	.text_sides {
		flex-direction: column;
		align-items: flex-start
	}
	.text_side:last-child,
	.para_side:last-child {
		width: 100%;
		max-width: 100%;
		margin-top: 20px;
	}
	.text_side p:not(:first-child) {
		margin-top: 20px;
	}
	.text_side h4 > div {
		display: inline;
	}
	.story_head,
	.para_side {
		width: 100%;
	}
	.story_media {
		padding-top: 16.41vw;
		width: 100%
	}
	.story_image:nth-child(1) {
		width: 50.1539vw;
		height: 37.616vw;
	}
	.story_image:nth-child(2) {
		width: 30.25vw;
		height: 22.694vw;
		margin-bottom: 4vw;
	}
	.story_image:nth-child(3) {
		width: 32vw;
		height: 41.064vw;
		margin-bottom: 13vw;
		right: 12.9166vw;
	}
	.story_head p {
		margin: 4vw 0 5vw
	}
	.floors_section {
		height: auto;
	}
	.details_box {
		height: auto;
		left: 0;
		padding: 80px 20px;
		padding-left: 100px;
		width: 100vw;
		position: relative;
	}
	.details_box p {
		padding: 5vw 0;
	}
	.box_close {
		display: none;
	}
	.gallery_nav h6 {
		padding: 5px;
	}
	.gallery_wrap {
		padding: 0 20px;
	}
	.gallery_col {
		padding: 20px;
	}
	.gallery_col i {
		height: 85vw;
		margin-bottom: 20px;
	}
	.footer_btm {
		flex-direction: column;
		align-items: flex-start;
	}
}




































